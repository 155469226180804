import { store } from "../../../../store/index";
import Api from "../../../../store/Services/Api";

/** 
 * javascript comment 
 * @Author: Ravi Sigdel 
 * @Date: 2021-03-26 15:12:00 
 * @Desc: Role Services 
 */
class RoleService {
    getDataForEstimateCreation() {
        const url = 'api/estimate/create'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    storeRole(data) {
        const url = 'api/role/store'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, data, headers);
    }

    getRoles() {
        const url = 'api/roles'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getFilteredRoles(queryParams) {
        const url = `api/roles?page-index=${queryParams.pageIndex}`
        const params = {
            'searched': queryParams.searched,
            'page-reload': queryParams.page_reload,
            'offset': queryParams.offset
        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);

    }
    getSelectedRoleData(id) {
        const url = 'api/roles/' + id
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    updateRole(data, id) {
        const url = 'api/role/update/' + id
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, data, headers);
    }

    deleteRole(id) {
        const url = 'api/role/delete/' + id
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url, headers);
    }

    getPermissions() {
        const url = 'api/role/permissions'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }


}

export default new RoleService();